import React from "react";
import { FaCheck, FaCross, FaPhone } from "react-icons/fa6";

export const Availability = (props) => {
  function currentlyAvailable() {
    // If between 9 and 21 take a 75% chance of being available, this should pesist every 3 minutes.
    // const now = new Date();
    // const hour = now.getHours();
    // const minute = now.getMinutes();
    // const second = now.getSeconds();
    // if (hour >= 9 && hour <= 21) {
    //   if (minute % 3 === 0) {
    //     return Math.random() < 0.75;
    //   }
    // }
    return true;
  }

  return (
    <>
      {currentlyAvailable() ? (
        <div className="flex  justify-start gap-8 items-center">
          <div>
            Seth van der Bijl
            <div className="hidden -row md:flex gap-1">
              <div className=" bg-green-400 text-sm flex rounded-full text-white p-1">
                <FaCheck size={16} />
              </div>
              <p className="text-gray-600"> Currently Available</p>
            </div>
          </div>
          <a
            href={"Tel:+31640478645"}
            className="bg-gradient-to-br items-center flex gap-1 from-cyan-600 to-green-400 text-white font-bold py-2 px-4 rounded-lg mt-4"
          >
            <FaPhone /> +31 6 4047 8645
          </a>
          <img
            src={process.env.PUBLIC_URL + "/imgs/seth_office3.png"}
            className="hidden md:block rounded-lg shadow-xl w-20 h-20 md:w-32 md:h-32 z-20"
          ></img>
       
        </div>
      ) : (
        <div>
          <div>
            Seth van der Bijl
            <div className="flex gap-1">
              <div className="bg-red-400 text-sm flex rounded-full text-white p-1">
                <FaCross size={16} />
              </div>
              <p className="text-gray-600"> Currently not Available</p>
            </div>
          </div>
          <a
            href={"Tel:+31640478645"}
            className="bg-gradient-to-br items-center flex gap-1 from-cyan-600 to-green-400 text-white font-bold py-2 px-4 rounded-lg mt-4"
          >
            <FaPhone /> +31 6 4047 8645
          </a>
        </div>
      )}
    </>
  );
};
